function findValueBySuffix(obj, suffix) {
  const keys = Object.keys(obj);
  const foundKey = keys.find((key) => key.endsWith(suffix));

  if (foundKey) {
    return obj[foundKey].trim();
  } else {
    return true; // Or any default value if the key isn't found
  }
}

const arrayToObject = (arr) => {
  const obj = {};

  arr
    .filter((item) => !!item && !!findValueBySuffix(item, "value"))
    .forEach((element, index) => {
      obj[index] = element;
    });

  return obj;
};

function objectToArray(obj) {
  const arr = [];

  Object.keys(obj).forEach((key) => {
    arr.push(obj[key]);
  });

  return arr;
}

export const convertArraysToObject = (obj) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (Array.isArray(obj[key])) {
        obj[key] = arrayToObject(obj[key]);
      }
    }
  }
  return obj;
};

export const convertObjectsToArray = (obj) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        obj[key] = objectToArray(obj[key]);
      }
    }
  }
  return obj;
};
