import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  CheckBox,
  Platform,
  Pressable,
  Image,
} from "react-native";
import Input from "./Input";
import { AntDesign, Ionicons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { profileActions } from "../store/slices/profile";
import { connect, useDispatch } from "react-redux";
import ImagePlaceHolder from "./ImagePlaceHolder";
import { useEffect } from "react";
import { notificationActions } from "../store/slices/notification";
import CustomText from "./CustomText";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Accordion = ({
  data,
  populateTemplateData,
  setData,
  setKeyData,
  setObjectData,
  addObject,
  deleteObject,
  expanded,
  toggleExpand,
}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");

  const openModal = (screen, key, extra = {}) => {
    return new Promise((resolve) => {
      navigation.navigate(screen, {
        key: key,
        ...extra,
        onModalClose: (value) => {
          console.log("Received value from modal:", value);
          resolve(value); // Resolve the promise with the received value
        },
      });
    });
  };

  const renderItem = ({ item, index: mainIndex }) => {
    const isExpanded = expanded[mainIndex];
    return (
      <View style={styles.itemContainer}>
        <TouchableOpacity onPress={() => toggleExpand(mainIndex)}>
          <CustomText style={styles.title}>
            <View
              style={{
                marginRight: 12,
                width: 28,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 14,
                backgroundColor: "#DB5E71",
                aspectRatio: 1 / 1,
              }}
            >
              <CustomText style={[styles.title, { color: "white" }]}>
                {mainIndex + 1}
              </CustomText>
            </View>
            {item.title}
          </CustomText>
        </TouchableOpacity>
        {isExpanded && (
          <View style={styles.expandedContent}>
            {item?.fields?.map((field) => {
              return (
                <View
                  style={{
                    rowGap: 8,
                    marginBottom: 12,
                    flexDirection: field.type === "CHIPS" ? "row" : "column",
                    flexWrap: field.type === "CHIPS" ? "wrap" : "nowrap",
                    columnGap: 12,
                  }}
                >
                  {field.type !== "EVENT" && field.type !== "CHIPS" && (
                    <CustomText style={styles.fieldTitle}>
                      {field.title}
                    </CustomText>
                  )}
                  {!!field.detail && (
                    <CustomText style={styles.fieldDetail}>
                      {field.detail}
                    </CustomText>
                  )}
                  {field.type === "TEXT_BOX" && (
                    <Input
                      // label="OTP"
                      placeholder={field.placeholder}
                      multiline={field.max_lines > 1}
                      boxHeight={
                        field.max_lines > 1 ? 21 * field.max_lines : 42
                      }
                      styleProp={{
                        // borderColor: "#f09aab",
                        // borderWidth: 1,
                        padding: 12,
                        borderRadius: 12,
                      }}
                      value={populateTemplateData[`${item.key}_${field.key}`]}
                      onChangeText={(e) => {
                        setData({
                          key: `${item.key}_${field.key}`,
                          value: e,
                        });
                      }}
                    />
                  )}
                  {field.type === "MEDIA" && (
                    <View
                      style={{
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      {Array.from({ length: field.max }, (_, index) => {
                        return (
                          <View style={{ width: "30%" }}>
                            <ImagePlaceHolder
                              key={index}
                              uri={
                                !!populateTemplateData[
                                  `${item.key}_${field.key}`
                                ] &&
                                populateTemplateData[
                                  `${item.key}_${field.key}`
                                ][index]?.url
                              }
                              onPress={async () => {
                                const returnValue = await openModal(
                                  "ChooseFile",
                                  "IMAGES"
                                );
                                setObjectData({
                                  value: returnValue,
                                  key: `${item.key}_${field.key}`,
                                  index,
                                });

                                console.log(returnValue);
                              }}
                            />
                          </View>
                        );
                      })}
                    </View>
                  )}
                  {field.type === "EVENT" && (
                    <>
                      {populateTemplateData[`${item.key}_${field.key}`].map(
                        (value, index) => {
                          return (
                            <>
                              {/* <View
                                style={{
                                  backgroundColor: "#DB5E71",
                                  padding: 4,
                                  borderRadius: 4,
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                              >
                                <CustomText
                                  style={[
                                    styles.fieldTitle,
                                    { color: "white" },
                                  ]}
                                >
                                  {field.title} {index + 1}
                                </CustomText>
                                <TouchableOpacity
                                  onPress={() => {
                                    deleteObject({
                                      key: `${item.key}_${field.key}`,
                                      index: index,
                                    });
                                  }}
                                >
                                  <MaterialIcons
                                    name="delete"
                                    size={16}
                                    color="white"
                                  />
                                </TouchableOpacity>
                              </View> */}
                              {field.fields.map((innerField) => (
                                <>
                                  {innerField.type !== "SUB_INPUT" &&
                                    innerField.type !== "SUB_SELECTION" &&
                                    innerField.type !== "TEXT_BOX" && (
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          width: "100%",
                                          alignItems: "center",
                                          justifyContent:
                                            innerField.type === "CHECKBOX"
                                              ? "center"
                                              : "flex-start",
                                          columnGap: 6,
                                        }}
                                      >
                                        {innerField.type === "CHECKBOX" && (
                                          <CheckBox
                                            value={
                                              value[innerField.key] ===
                                              "enabled"
                                                ? true
                                                : false
                                            }
                                            tintColors={{
                                              true: "red",
                                              false: "white",
                                            }}
                                            onValueChange={() => {
                                              setKeyData({
                                                key: `${item.key}_${field.key}`,
                                                value:
                                                  value[innerField.key] !==
                                                  "enabled"
                                                    ? "enabled"
                                                    : "disabled",
                                                index,
                                                innerKey: innerField.key,
                                              });
                                            }}
                                          />
                                        )}
                                        <CustomText style={styles.fieldTitle}>
                                          {innerField.title}
                                        </CustomText>
                                      </View>
                                    )}

                                  {innerField.type === "TEXT_BOX" && (
                                    <View
                                      style={{
                                        columnGap: 30,
                                        flexDirection: "row",
                                        paddingHorizontal: 12,
                                        marginBottom: 12,
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Input
                                        placeholder={innerField.placeholder}
                                        multiline={innerField.max_lines > 1}
                                        boxHeight={
                                          innerField.max_lines > 1
                                            ? 21 * innerField.max_lines
                                            : 42
                                        }
                                        value={value[innerField.key]}
                                        styleProp={{
                                          // borderColor: "#f09aab",
                                          // borderWidth: 1,
                                          padding: 12,
                                          borderRadius: 12,
                                        }}
                                        onChangeText={(e) => {
                                          setKeyData({
                                            key: `${item.key}_${field.key}`,
                                            value: e,
                                            index,
                                            innerKey: innerField.key,
                                          });
                                        }}
                                        deleteButton={() => {
                                          deleteObject({
                                            key: `${item.key}_${field.key}`,
                                            index: index,
                                          });
                                        }}
                                      />
                                    </View>
                                  )}
                                  {innerField.type === "SUB_INPUT" && (
                                    <Pressable
                                      onPress={
                                        value.select
                                          ? async () => {
                                              const returnValue =
                                                await openModal(
                                                  "ChooseSubInput",
                                                  innerField.selection_type,
                                                  {
                                                    value:
                                                      value[
                                                        `${
                                                          innerField.key.split(
                                                            "_"
                                                          )[0]
                                                        }_value`
                                                      ],
                                                  }
                                                );
                                              setObjectData({
                                                value: {
                                                  [innerField.key]:
                                                    returnValue.type,
                                                  link_show_header: "disabled",

                                                  event_clip: returnValue.icon,
                                                  [`${
                                                    innerField.key.split("_")[0]
                                                  }_value`]: returnValue.value,
                                                },
                                                key: `${item.key}_${field.key}`,
                                                index,
                                              });
                                            }
                                          : () => {}
                                      }
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        columnGap: 20,
                                        paddingHorizontal: 12,
                                        paddingVertical: 4,
                                      }}
                                    >
                                      {value?.event_clip?.url && (
                                        <View
                                          style={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                            rowGap: 8,
                                            width: 80,
                                          }}
                                        >
                                          <Image
                                            style={{ width: 28, height: 28 }}
                                            source={{
                                              uri: value.event_clip?.url,
                                            }}
                                          />
                                          <CustomText
                                            style={{
                                              fontWeight: 500,
                                              fontSize: 14,
                                            }}
                                          >
                                            {value?.link_type}
                                          </CustomText>
                                        </View>
                                      )}

                                      {value.select ? (
                                        <View
                                          style={{
                                            flex: 1,
                                            flexDirection: "row",
                                            alignItems: "center",
                                            padding: 12,
                                            borderRadius: 12,
                                            backgroundColor: "#FFFFFF",
                                            justifyContent: "space-between",
                                            ...Platform.select({
                                              web: {
                                                boxShadow:
                                                  "rgba(17, 12, 46, 0.05) 0px 6px 12px 0px",
                                              },
                                              android: { elevation: 10 },
                                              ios: {
                                                shadowColor:
                                                  "rgba(0, 0, 0, 0.16)",
                                                shadowOffset: {
                                                  width: 0,
                                                  height: 1,
                                                },
                                                shadowOpacity: 1,
                                                shadowRadius: 4,
                                              },
                                            }),
                                          }}
                                        >
                                          <CustomText
                                            style={{
                                              fontWeight: 400,
                                              // color: "#858490",
                                            }}
                                          >
                                            {
                                              value[
                                                `${
                                                  innerField.key.split("_")[0]
                                                }_value`
                                              ]
                                            }
                                          </CustomText>
                                          <TouchableOpacity
                                            onPress={() => {
                                              deleteObject({
                                                key: `${item.key}_${field.key}`,
                                                index: index,
                                              });
                                            }}
                                          >
                                            <Ionicons
                                              name="ios-close-sharp"
                                              size={16}
                                              color="black"
                                            />
                                          </TouchableOpacity>
                                        </View>
                                      ) : (
                                        <View
                                          style={{
                                            columnGap: 30,
                                            flexDirection: "row",
                                            flex: 1,
                                            marginBottom: 12,
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Input
                                            placeholder={innerField.placeholder}
                                            multiline={innerField.max_lines > 1}
                                            boxHeight={
                                              innerField.max_lines > 1
                                                ? 21 * innerField.max_lines
                                                : 42
                                            }
                                            value={
                                              value[
                                                `${
                                                  innerField.key.split("_")[0]
                                                }_value`
                                              ]
                                            }
                                            styleProp={{
                                              // borderColor: "#f09aab",
                                              // borderWidth: 1,
                                              padding: 12,
                                              borderRadius: 12,
                                            }}
                                            onChangeText={(e) => {
                                              setKeyData({
                                                key: `${item.key}_${field.key}`,
                                                value: e,
                                                index,
                                                innerKey: `${
                                                  innerField.key.split("_")[0]
                                                }_value`,
                                              });
                                            }}
                                            deleteButton={() => {
                                              deleteObject({
                                                key: `${item.key}_${field.key}`,
                                                index: index,
                                              });
                                            }}
                                          />
                                        </View>
                                      )}
                                    </Pressable>
                                  )}
                                  {innerField.type === "SUB_SELECTION" && (
                                    <>
                                      <View
                                        style={{
                                          alignItems: "flex-start",
                                          rowGap: 8,
                                          width: "100%",
                                        }}
                                      >
                                        <CustomText
                                          style={{
                                            color: "#740025",
                                            paddingLeft: 12,
                                            fontWeight: 500,
                                          }}
                                        >
                                          {value[innerField.key]}
                                        </CustomText>
                                        {value.select ? (
                                          <Pressable
                                            onPress={async () => {
                                              const returnValue =
                                                await openModal(
                                                  "ChooseSubSelection",
                                                  innerField.selection_type
                                                );
                                              setObjectData({
                                                value: {
                                                  [innerField.key]:
                                                    returnValue.type,
                                                  event_clip: returnValue.icon,
                                                  [`${
                                                    innerField.key.split("_")[0]
                                                  }_value`]: returnValue.value,
                                                },
                                                key: `${item.key}_${field.key}`,
                                                index,
                                              });
                                            }}
                                            style={{
                                              width: "100%",
                                              alignItems: "center",
                                              flexDirection: "row",
                                              padding: 12,
                                              borderRadius: 12,
                                              backgroundColor: "#FFFFFF",
                                              justifyContent: "space-between",
                                              ...Platform.select({
                                                web: {
                                                  boxShadow:
                                                    "rgba(17, 12, 46, 0.05) 0px 6px 12px 0px",
                                                },
                                                android: { elevation: 10 },
                                                ios: {
                                                  shadowColor:
                                                    "rgba(0, 0, 0, 0.16)",
                                                  shadowOffset: {
                                                    width: 0,
                                                    height: 1,
                                                  },
                                                  shadowOpacity: 1,
                                                  shadowRadius: 4,
                                                },
                                              }),
                                            }}
                                          >
                                            <CustomText
                                              style={{
                                                fontWeight: 400,
                                                // color: "#858490",
                                              }}
                                            >
                                              {
                                                value[
                                                  `${
                                                    innerField.key.split("_")[0]
                                                  }_value`
                                                ]
                                              }
                                            </CustomText>
                                            <TouchableOpacity
                                              onPress={() => {
                                                deleteObject({
                                                  key: `${item.key}_${field.key}`,
                                                  index: index,
                                                });
                                              }}
                                            >
                                              <Ionicons
                                                name="ios-close-sharp"
                                                size={16}
                                                color="black"
                                              />
                                            </TouchableOpacity>
                                          </Pressable>
                                        ) : (
                                          <View
                                            style={{
                                              columnGap: 30,
                                              flexDirection: "row",
                                              width: "100%",
                                              marginBottom: 12,
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Input
                                              placeholder={
                                                innerField.placeholder
                                              }
                                              multiline={
                                                innerField.max_lines > 1
                                              }
                                              boxHeight={
                                                innerField.max_lines > 1
                                                  ? 21 * innerField.max_lines
                                                  : 42
                                              }
                                              value={
                                                value[
                                                  `${
                                                    innerField.key.split("_")[0]
                                                  }_value`
                                                ]
                                              }
                                              styleProp={{
                                                // borderColor: "#f09aab",
                                                // borderWidth: 1,
                                                padding: 12,
                                                borderRadius: 12,
                                              }}
                                              onChangeText={(e) => {
                                                setKeyData({
                                                  key: `${item.key}_${field.key}`,
                                                  value: e,
                                                  index,
                                                  innerKey: `${
                                                    innerField.key.split("_")[0]
                                                  }_value`,
                                                });
                                              }}
                                              deleteButton={() => {
                                                deleteObject({
                                                  key: `${item.key}_${field.key}`,
                                                  index: index,
                                                });
                                              }}
                                            />
                                          </View>
                                        )}
                                      </View>
                                    </>
                                  )}
                                </>
                              ))}
                            </>
                          );
                        }
                      )}
                      <TouchableOpacity
                        style={{ padding: 16, alignItems: "center" }}
                        onPress={() => {
                          addObject({
                            key: `${item.key}_${field.key}`,
                            innerKey: field.key,
                          });
                        }}
                      >
                        <CustomText
                          style={{
                            color: "#740025",
                            fontWeight: 400,
                            fontSize: 18,
                          }}
                        >
                          ADD MORE
                        </CustomText>
                      </TouchableOpacity>
                    </>
                  )}
                  {field.type === "CHIPS" && (
                    <>
                      {populateTemplateData[`${item.key}_${field.key}`].map(
                        (value, index) => {
                          return (
                            <>
                              {field.fields.map((innerField) => (
                                <>
                                  {innerField.type === "CHIP" && (
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        padding: 12,
                                        columnGap: 12,
                                        marginBottom: 12,
                                        borderRadius: 12,
                                        backgroundColor: "#FFFFFF",
                                        justifyContent: "space-between",
                                        ...Platform.select({
                                          web: {
                                            boxShadow:
                                              "rgba(17, 12, 46, 0.05) 0px 6px 12px 0px",
                                          },
                                          android: { elevation: 10 },
                                          ios: {
                                            shadowColor: "rgba(0, 0, 0, 0.16)",
                                            shadowOffset: {
                                              width: 0,
                                              height: 1,
                                            },
                                            shadowOpacity: 1,
                                            shadowRadius: 4,
                                          },
                                        }),
                                      }}
                                    >
                                      <CustomText
                                        style={{
                                          fontWeight: 400,
                                          fontSize: 18,
                                          // color: "#858490",
                                        }}
                                      >
                                        {value[innerField.key]}
                                      </CustomText>
                                      <TouchableOpacity
                                        onPress={() => {
                                          deleteObject({
                                            key: `${item.key}_${field.key}`,
                                            index: index,
                                          });
                                        }}
                                      >
                                        <Ionicons
                                          name="ios-close-sharp"
                                          size={16}
                                          color="black"
                                        />
                                      </TouchableOpacity>
                                    </View>
                                  )}
                                </>
                              ))}
                            </>
                          );
                        }
                      )}
                      <View style={{ rowGap: 8, width: "100%" }}>
                        <CustomText style={styles.fieldTitle}>
                          Add {field.title}
                        </CustomText>
                        <View
                          style={{
                            columnGap: 30,
                            flexDirection: "row",
                            marginBottom: 12,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Input
                            placeholder={field.placeholder}
                            value={inputValue}
                            styleProp={{
                              // borderColor: "#f09aab",
                              // borderWidth: 1,
                              padding: 12,
                              borderRadius: 12,
                            }}
                            onChangeText={(e) => {
                              setInputValue(e);
                            }}
                          />
                          <TouchableOpacity
                            onPress={() => {
                              if (inputValue.trim()) {
                                addObject({
                                  key: `${item.key}_${field.key}`,
                                  innerKey: field.key,
                                });
                                setKeyData({
                                  key: `${item.key}_${field.key}`,
                                  value: inputValue,
                                  index:
                                    populateTemplateData[
                                      `${item.key}_${field.key}`
                                    ].length,
                                  innerKey: field.fields[0].key,
                                });
                                setInputValue("");
                              } else {
                                dispatch(
                                  notificationActions.toggleError("Enter value")
                                );
                              }
                            }}
                          >
                            <AntDesign name="check" size={16} color="black" />
                          </TouchableOpacity>
                        </View>
                      </View>
                      {/* <TouchableOpacity
                        style={{ padding: 16, alignItems: "center" }}
                        onPress={() => {
                          addObject({
                            key: `${item.key}_${field.key}`,
                            innerKey: field.key,
                          });
                        }}
                      >
                        <CustomText style={{ color: "#DB5E71", fontWeight: "bold" }}>
                          ADD
                        </CustomText>
                      </TouchableOpacity> */}
                    </>
                  )}
                </View>
              );
            })}
            <TouchableOpacity
              style={{ padding: 16 }}
              onPress={() =>
                toggleExpand(mainIndex + 1 >= data.length ? 0 : mainIndex + 1)
              }
            >
              <CustomText
                style={{
                  color: "#DB5E71",
                  fontWeight: 400,
                  fontSize: 18,
                }}
              >
                NEXT
              </CustomText>
            </TouchableOpacity>
          </View>
        )}
        {/* {index < data.length - 1 && <View style={styles.line} />} */}
      </View>
    );
  };

  return (
    <FlatList
      data={data}
      showsVerticalScrollIndicator={false}
      renderItem={renderItem}
      keyExtractor={(item, index) => index.toString()}
    />
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: "#DB5E71",
  },
  fieldTitle: {
    color: "#740025",
    paddingLeft: 12,
    fontWeight: 500,
  },
  fieldDetail: {
    paddingLeft: 12,
    fontSize: 12,
    color: "fafafa",
  },
  expandedContent: {
    marginTop: 6,
    padding: 16,
  },
  line: {
    height: 1,
    backgroundColor: "gray",
  },
});

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setData: (data) => dispatch(profileActions.updateTemplateData(data)),
  setKeyData: (data) =>
    dispatch(profileActions.updateTemplateObjectKeyData(data)),
  setObjectData: (data) =>
    dispatch(profileActions.updateTemplateObjectData(data)),
  addObject: (data) => dispatch(profileActions.addTemplateData(data)),
  deleteObject: (data) => dispatch(profileActions.deleteTemplateData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accordion);
