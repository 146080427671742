import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  Platform,
  View,
  TextInput,
  TouchableOpacity,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  AntDesign,
  Entypo,
  FontAwesome,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import * as Linking from "expo-linking";
import ListView from "../components/ListView";
import { getCoupons, placeOrder, validateOrder } from "../store/actions/cms";
import { notificationActions } from "../store/slices/notification";
import { CommonActions } from "@react-navigation/native";
import CustomText from "../components/CustomText";
import { FlatList } from "react-native-gesture-handler";

const CheckoutScreen = ({
  route,
  loading,
  getData,
  coupons,
  token,
  navigation,
}) => {
  const { onModalClose } = route.params;

  const [isFocused, setIsFocused] = useState(0);

  const fetchData = async () => {
    await getData(token);
  };

  useEffect(() => {
    if (coupons.length === 0) fetchData();
  }, []);

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Apply PromoCode</CustomText>
      </View>
      <View style={styles.container}>
        <>
          <View
            style={{
              rowGap: 16,
            }}
          >
            <CustomText
              style={{
                fontWeight: 300,

                color: "#8D8D8D",
                fontSize: 16,
              }}
            >
              Select one from the list
            </CustomText>
          </View>

          <FlatList
            data={coupons}
            showsVerticalScrollIndicator={false}
            renderItem={(itemData) => {
              const { item } = itemData;
              return (
                <TouchableOpacity
                  style={{
                    marginBottom: 16,
                    marginHorizontal: 16,
                    flexDirection: "row",
                    overflow: "hidden",
                    borderRadius: 20,
                    ...Platform.select({
                      web: {
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      },
                      android: {
                        elevation: 10,
                      },
                      ios: {
                        shadowColor: "rgba(0, 0, 0, 0.16)",
                        shadowOffset: {
                          width: 0,
                          height: 1,
                        },
                        shadowOpacity: 1,
                        shadowRadius: 4,
                      },
                    }),
                  }}
                  onPress={() => setIsFocused(itemData.index)}
                >
                  <View
                    style={{
                      flex: 1,
                      padding: 8,
                      backgroundColor:
                        itemData.index === isFocused ? "#FDEBE8" : "#DB5E71",
                      heigth: "100%",
                    }}
                  >
                    <View style={styles.rotatedTextContainer}>
                      <CustomText
                        style={{
                          transform: [{ rotate: "-90deg" }],
                          width: "max-content",
                          fontSize: 16,
                          fontWeight: 400,
                          color: itemData.index === isFocused ? "#000" : "#fff",
                        }}
                      >
                        {itemData.item.is_flat
                          ? `₹${itemData.item.amount} off`
                          : `${itemData.item.percentage}% off`}
                      </CustomText>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 9,
                      padding: 16,
                      backgroundColor:
                        itemData.index === isFocused ? "#DB5E71" : "#E7E7E7",
                      rowGap: 12,
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <CustomText
                      style={{
                        fontSize: 20,
                        color: itemData.index !== isFocused ? "#000" : "#fff",
                      }}
                    >
                      {item.title}
                    </CustomText>
                    <CustomText
                      style={{
                        fontSize: 12,
                        color: itemData.index !== isFocused ? "#000" : "#fff",
                        maxWidth: "90%",
                      }}
                    >
                      {item.detail}
                    </CustomText>
                  </View>
                </TouchableOpacity>
              );
            }}
            alwaysBounceVertical={false}
            ListHeaderComponent={
              <View
                style={{
                  width: "100%",
                  paddingVertical: 16,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CustomText style={{ fontSize: 20 }}>
                  Select Promo Code
                </CustomText>
              </View>
            }
          />

          <View style={styles.lowerContainer}>
            <Pressable
              style={styles.button}
              onPress={() => {
                onModalClose(coupons[isFocused].title);
                navigation.goBack();
              }}
            >
              <CustomText style={styles.buttonText}>Apply</CustomText>
            </Pressable>
          </View>
        </>
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.notification?.loadingSpinner,
  config: state?.cms?.config,
  token: state?.user?.token,
  coupons: state?.cms?.coupons,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (token) => dispatch(getCoupons(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 4,
    padding: 16,
    paddingTop: 0,
  },
  rotatedTextContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  divider: {
    width: "100%",
    marginVertical: 2,
    height: StyleSheet.hairlineWidth,
    backgroundColor: "#a2a2a2",
  },
  upperContainer: {
    padding: 16,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#DB5E71",
    fontSize: 32,
    fontWeight: 600,
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#DB5E71",
    width: "90%",
    paddingVertical: 20,
    borderRadius: 20,
    paddingHorizontal: 20,
    position: "fixed",
    bottom: 35,
    maxWidth: 440,
    alignItems: "center",
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  buttonText: {
    color: "white",
    fontSize: 20,
  },

  categoryContainer: {
    flexDirection: "column",
    marginTop: "10%",
    padding: 20,
    backgroundColor: "#FDEBE8",
    borderRadius: 20,
    rowGap: 20,
    justifyContent: "center",
    alignItems: "flex-start",
    marginBottom: 6,
    ...Platform.select({
      web: {
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
});
