import { Ionicons } from "@expo/vector-icons";
import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  Platform,
  TouchableOpacity,
} from "react-native";

export default function Input({
  label,
  boxHeight = 42,
  onFocus = () => {},
  styleProp = {},
  deleteButton,
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <View
      style={{
        width: "100%",
        flex: 1,
        // backgroundColor: "black",
        borderRadius: 8,
        // overflow: "hidden",
        height: boxHeight,
        minHeight: boxHeight,
        borderRadius: 12,
        ...Platform.select({
          web: { boxShadow: "rgba(17, 12, 46, 0.05) 0px 6px 12px 0px" },
          android: { elevation: 10 },
          ios: {
            shadowColor: "rgba(0, 0, 0, 0.16)",
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 1,
            shadowRadius: 4,
          },
        }),
      }}
    >
      {/* {label && <CustomText style={styles.label}>{label}</CustomText>} */}
      <View
        style={[
          styles.inputContainer,
          {
            borderColor: isFocused ? "#7975b5" : "#f3f4fb",
            height: boxHeight,
            position: "relative",
            borderRadius: 12,
            minHeight: boxHeight,
          },
        ]}
      >
        {/* <Icon type="font-awesome" name={iconName} style={styles.icon} /> */}
        <TextInput
          {...props}
          style={[
            styles.input,
            {
              height: boxHeight,
              minHeight: boxHeight,
            },
            styleProp,
          ]}
          placeholderTextColor="#858490"
          autoCorrect={false}
          onFocus={() => {
            onFocus();
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
        {deleteButton && (
          <TouchableOpacity
            style={{ position: "absolute", right: 12 }}
            onPress={deleteButton}
          >
            <Ionicons name="ios-close-sharp" size={16} color="black" />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  label: {
    marginVertical: 5,
    color: "#fff",
    fontSize: 16,

    fontWeight: "bold",
  },
  inputContainer: {
    backgroundColor: "#fff",
    flexDirection: "row",
    width: "100%",
    borderRadius: 4,
    borderWidth: 0.5,
    alignItems: "center",
  },
  input: {
    color: "#000",
    fontWeight: 400,
    width: "100%",
    paddingHorizontal: 8,
  },
  error: {
    color: "red",
    fontSize: 12,
    marginTop: 7,
  },
});
