import {
  Pressable,
  StyleSheet,
  Text,
  View,
  Platform,
  FlatList,
  ActivityIndicator,
  Image,
  TextInput,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getReviews, getTemplateMesasgeData } from "../store/actions/cms";
import BackdropModal from "../components/BackdropModal";
import { notificationActions } from "../store/slices/notification";
import { capitalize } from "lodash";
import CustomText from "../components/CustomText";

const ChooseSubInputScreen = ({
  templateMessageData,
  route,
  token,
  loading,
  getData,
  navigation,
  categoryId,
  templateId,
}) => {
  const { key, onModalClose, value: prevValue } = route.params;
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [qiType, setQiType] = useState("");
  const [value, setValue] = useState(
    prevValue && prevValue !== "www.example.com" ? prevValue : ""
  );

  useEffect(() => {
    if (!categoryId || !templateId) {
      setTimeout(
        () =>
          navigation.replace("Homeroutes", {
            screen: "Dashboard",
          }),
        100
      );
    } else {
      if (!templateMessageData) getData(token);
    }
  }, [categoryId, templateMessageData]);

  const handleAdd = () => {
    if (!value) {
      setModalVisible(false);
      dispatch(notificationActions.toggleError("Enter Value"));
    } else {
      onModalClose({
        type: qiType.title,
        icon: qiType.icon,
        value: value,
      });
      navigation.goBack();
    }
  };

  const handleSelection = (data) => {
    console.log(data);
    setQiType(data.data);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };
  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>
          {capitalize(key.replace("_", " "))}
        </CustomText>
      </View>
      <View style={styles.container}>
        <View style={{ flex: 1 }}>
          <FlatList
            data={templateMessageData?.filter(
              (item) => item.template_type === key
            )}
            numColumns={3}
            showsVerticalScrollIndicator={false}
            renderItem={(itemData) => {
              return (
                <Pressable
                  style={styles.categoryContainer}
                  onPress={() => handleSelection(itemData.item)}
                >
                  <Image
                    source={{ uri: itemData?.item?.data?.icon?.url }}
                    style={{ width: 52, aspectRatio: 1 / 1 }}
                  />
                  <CustomText style={styles.primaryHeading}>
                    {itemData.item?.data?.title}
                  </CustomText>
                </Pressable>
              );
            }}
            keyExtractor={(item) => {
              return item.data.title;
            }}
            alwaysBounceVertical={false}
          />
        </View>
      </View>
      <BackdropModal
        visible={modalVisible}
        onClose={handleModalClose}
        Content={
          <View style={{ backgroundColor: "white", padding: 16 }}>
            <CustomText style={styles.modalHeading}>{qiType.title}</CustomText>
            {qiType.description && (
              <CustomText style={{ marginBottom: 10 }}>
                {qiType.description}
              </CustomText>
            )}
            <TextInput
              placeholder="Enter something..."
              onChangeText={setValue}
              value={value}
              style={styles.input}
            />
            <View style={styles.lowerContainer}>
              <View
                style={{
                  flex: 1,
                  padding: 6,
                  columnGap: 5,
                  flexDirection: "row",
                }}
              >
                <Pressable style={styles.button} onPress={handleModalClose}>
                  <CustomText style={styles.buttonText}>Cancel</CustomText>
                </Pressable>
                <Pressable style={styles.button} onPress={handleAdd}>
                  <CustomText style={styles.buttonText}>Add</CustomText>
                </Pressable>
              </View>
            </View>
          </View>
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  templateMessageData: state?.cms?.formMessageData,
  token: state?.user?.token,
  templateId: state?.profile?.templateId,
  categoryId: state?.profile?.categoryId,

  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (token) => dispatch(getTemplateMesasgeData(token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseSubInputScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 4,
    marginTop: 16,
  },
  upperContainer: {
    padding: 8,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#32505C",
    fontSize: 24,
    fontWeight: 400,
  },
  primaryHeading: {
    fontWeight: "bold",
    fontSize: 16,
  },
  categoryContainer: {
    flex: 1,
    margin: 16,
    rowGap: 4,
    alignItems: "center",
    paddingVertical: 16,
    backgroundColor: "#ffffff",
    borderRadius: 12,
    justifyContent: "center",
    ...Platform.select({
      web: {
        boxShadow: "rgba(17, 12, 46, 0.05) 0px 6px 12px 0px",
      },
      android: { elevation: 10 },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  modalHeading: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  input: {
    height: 40,
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  lowerContainer: {
    height: 48,
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#DB5E71",
    flexDirection: "row",
    flex: 1,
    paddingVertical: 3,
    paddingHorizontal: 10,
    justifyContent: "center",
    columnGap: 2,
    alignItems: "center",
    borderRadius: 4,
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
});
