import {
  ActivityIndicator,
  Image,
  Pressable,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
  Clipboard,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  AntDesign,
  Entypo,
  Feather,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import * as Linking from "expo-linking";

import {
  generatePdfLink,
  getOrders,
  getTemplateData,
  getTemplatePreData,
} from "../store/actions/cms";
import { notificationActions } from "../store/slices/notification";
import { generateShareContent } from "../utils/generateMessage";
import shareMessage from "../utils/shareMessage";
import CustomText from "../components/CustomText";
import WebView from "../components/WebView";
import BackdropModal from "../components/BackdropModal";
import { loginRequired } from "../utils/loginRequired";

const OrderHistoryScreen = ({
  getData,
  loading,
  orderData,
  token,
  navigation,

  setSelectedOrder,
  setSelectedOrderDetail,
  setModalVisible,
  setModalShareVisible,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      if (orderData.length === 0) getData(token);
    } else {
      loginRequired(navigation);
    }
  }, [token]);

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Order History</CustomText>
      </View>
      {orderData?.length === 0 ? (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <CustomText style={styles.mainHeading}>No orders yet</CustomText>
        </View>
      ) : (
        <ScrollView
          style={styles.container}
          showsVerticalScrollIndicator={false}
        >
          {orderData &&
            orderData.map((order) => (
              <Pressable
                style={styles.middleContainer}
                onPress={() => {
                  navigation.navigate("Pages", {
                    isOrderDetail: 1,
                    id: order?.items[0]?.username,
                  });
                }}
              >
                {/* IMage container */}
                <View
                  style={{
                    backgroundColor: "#F9C6A4",
                    padding: 16,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 16,
                    position: "relative",
                  }}
                >
                  <Image
                    source={{ uri: order.items[0]?.product?.image?.url }}
                    style={[
                      {
                        resizeMode: "contain",
                        width: 120,
                        height: 192,
                        borderRadius: 26,
                      },
                    ]}
                  />
                  {/* <WebView
                    uri="http://localhost:8000/index"
                    iStyle={{
                      borderWidth: 0,
                      width: 110,
                      height: 145,
                      borderRadius: 24,
                      overflow: "hidden",
                    }}
                  /> */}
                  <Pressable
                    style={styles.button}
                    onPress={async () => {
                      await dispatch(
                        getTemplatePreData(
                          token,
                          order.items[0]?.template_data_id
                        )
                      );
                      navigation.navigate("Create", {
                        edit: order.items[0]?.template_data_id,
                      });
                    }}
                  >
                    <Ionicons name="pencil-sharp" size={20} color="white" />
                  </Pressable>
                </View>

                {/* SECOND CONTAINER */}

                <View
                  style={{
                    rowGap: 20,
                    justifyContent: "space-evenly",
                    alignItems: "flex-start",
                    flex: 1,
                    padding: 8,
                  }}
                >
                  <View style={styles.flexContainer}>
                    <CustomText
                      style={[styles.phoneHeading, { fontWeight: "bold" }]}
                    >
                      {order.items[0]?.product?.name}
                    </CustomText>
                    <Pressable
                      onPress={async () => {
                        setSelectedOrderDetail(order);
                        setModalShareVisible(true);
                      }}
                    >
                      <Ionicons
                        name="share-social-sharp"
                        size={28}
                        color="#DB5E71"
                      />
                    </Pressable>
                  </View>
                  <CustomText style={styles.phoneHeading}>
                    OrderId #{order?.order_id?.split("-")[0]}
                  </CustomText>

                  <Pressable
                    style={{
                      flexDirection: "row",
                      columnGap: 10,
                      alignItems: "center",
                    }}
                    onPress={() =>
                      navigation.navigate("Review", { id: order.order_id })
                    }
                  >
                    <MaterialIcons name="comment" size={24} color="#DB5E71" />
                    <CustomText
                      style={[styles.buttonText, { color: "#DB5E71" }]}
                    >
                      Queries
                    </CustomText>
                  </Pressable>

                  <Pressable
                    style={[
                      styles.button,
                      { backgroundColor: "white", position: "relative" },
                    ]}
                    onPress={() => {
                      setSelectedOrder(order?.items[0]?.username);
                      setModalVisible(true);
                    }}
                  >
                    <AntDesign name="arrowdown" size={20} color="#DB5E71" />
                  </Pressable>
                </View>
              </Pressable>
            ))}
        </ScrollView>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  orderData: state?.cms?.orders,
  token: state?.user?.token,
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (token) => dispatch(getOrders(token)),

  setModalVisible: (data) =>
    dispatch(notificationActions.setModalVisible(data)),
  setSelectedOrder: (data) =>
    dispatch(notificationActions.setSelectedOrder(data)),
  setSelectedOrderDetail: (data) =>
    dispatch(notificationActions.setSelectedOrderDetail(data)),
  setModalShareVisible: (data) =>
    dispatch(notificationActions.setModalShareVisible(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 12,
  },
  flexContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: 28,
  },
  upperContainer: {
    padding: 8,
    alignItems: "start",
    justifyContent: "center",
    paddingLeft: 20,
  },
  mainHeading: {
    color: "#32505C",
    fontSize: 28,
  },
  middleContainer: {
    flexDirection: "row",
    width: "100%",
    columnGap: 16,
    marginBottom: 16,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 16,
  },
  button: {
    backgroundColor: "#DB5E71",
    padding: 8,
    borderRadius: 8,
    position: "absolute",
    bottom: 8,
    right: 8,
    alignItems: "center",
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  buttonText: {
    color: "white",
    fontSize: 20,
  },
  buttonBig: {
    backgroundColor: "#DB5E71",
    paddingVertical: 20,

    paddingHorizontal: 20,
    maxWidth: 440,
    alignItems: "center",
  },
  buttonBigText: {
    color: "white",
    fontSize: 20,
  },
  shadow: {
    width: "90%",
    borderRadius: 20,
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
});
