import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  Platform,
  View,
  TextInput,
  TouchableOpacity,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  AntDesign,
  Entypo,
  FontAwesome,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import * as Linking from "expo-linking";
import ListView from "../components/ListView";
import { placeOrder, validateOrder } from "../store/actions/cms";
import { notificationActions } from "../store/slices/notification";
import { CommonActions } from "@react-navigation/native";
import CustomText from "../components/CustomText";
import { loginRequired } from "../utils/loginRequired";

const CheckoutScreen = ({
  config,
  route,
  loading,
  validateOrder,
  token,
  placeOrder,
  navigation,
}) => {
  const { templateId, productId, username } = route.params;

  const dispatch = useDispatch();

  const [data, setData] = useState();
  const [text, setText] = useState("");
  const [coupon, setCoupon] = useState(false);

  const fetchData = async (coupon_code) => {
    const validateData = await validateOrder(token, {
      product_id: productId,
      template_data_id: templateId,
      username,
      coupon_code,
    });
    setData(validateData);
  };

  useEffect(() => {
    if (token) {
      if (!templateId || !productId || !username) {
        setTimeout(() => navigation.replace("Dashboard"), 100);
      } else {
        fetchData();
      }
    } else loginRequired(navigation);
  }, [token]);

  const handleClearText = () => {
    setText("");
  };

  const openModal = (extra = {}) => {
    return new Promise((resolve) => {
      navigation.navigate("ChoosePromo", {
        onModalClose: (value) => {
          console.log("Received value from modal:", value);
          resolve(value); // Resolve the promise with the received value
        },
      });
    });
  };

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Checkout Page</CustomText>
      </View>
      <View style={styles.container}>
        {data && (
          <>
            <View
              style={{
                rowGap: 16,
              }}
            >
              <CustomText
                style={{
                  fontWeight: 300,
                  color: "#8D8D8D",
                  fontSize: 16,
                }}
              >
                Order Summary
              </CustomText>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText
                  style={{ color: "#DB5E71", fontWeight: 400, fontSize: 24 }}
                >
                  Payable Amount
                </CustomText>
                <CustomText
                  style={{ fontWeight: 400, color: "#DB5E71", fontSize: 24 }}
                >
                  ₹{data.total_payable}
                </CustomText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText>Total Amount</CustomText>
                <CustomText>₹{data.total}</CustomText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText>Discount</CustomText>
                <CustomText>₹{data.discount}</CustomText>
              </View>

              {!!data.tax_value && !!data.tax_key && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <CustomText>{data.tax_key}</CustomText>
                  <CustomText>₹{data.tax_value}</CustomText>
                </View>
              )}

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText>Subtotal</CustomText>
                <CustomText>₹{data.subtotal}</CustomText>
              </View>

              {/* <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText style={{ color: "#407ce4", fontWeight: "bold" }}>
                  Total Saved
                </CustomText>
                <CustomText style={{ fontWeight: "bold", color: "#407ce4" }}>
                  ₹{data.total_saved}
                </CustomText>
              </View> */}
              {/* <View style={styles.divider}></View>

              <View style={styles.divider}></View> */}
            </View>

            <View style={styles.categoryContainer}>
              <CustomText
                style={{ color: "#32505C", fontSize: 16, fontWeight: 400 }}
              >
                Apply Promo Code
              </CustomText>
              {coupon ? (
                <View
                  style={{
                    width: "100%",
                    alignItems: "center",
                    flexDirection: "row",
                    padding: 12,
                    borderRadius: 12,
                    backgroundColor: "#FFFFFF",
                    justifyContent: "space-between",
                    ...Platform.select({
                      web: {
                        boxShadow: "rgba(17, 12, 46, 0.05) 0px 6px 12px 0px",
                      },
                      android: { elevation: 10 },
                      ios: {
                        shadowColor: "rgba(0, 0, 0, 0.16)",
                        shadowOffset: {
                          width: 0,
                          height: 1,
                        },
                        shadowOpacity: 1,
                        shadowRadius: 4,
                      },
                    }),
                  }}
                >
                  <CustomText
                    style={{
                      fontWeight: 500,
                      color: "#858490",
                    }}
                  >
                    {coupon}
                  </CustomText>
                  <TouchableOpacity
                    onPress={async () => {
                      setCoupon();
                      await fetchData();
                    }}
                  >
                    <Entypo name="cross" size={16} color="#CF4258" />
                    {/* <Ionicons name="ios-close-sharp" size={16} color="black" /> */}
                  </TouchableOpacity>
                </View>
              ) : (
                <Pressable
                  onPress={async () => {
                    const returnedValue = await openModal();
                    fetchData(returnedValue);
                    setCoupon(returnedValue);
                  }}
                  style={{
                    width: "100%",
                    alignItems: "center",
                    flexDirection: "row",
                    padding: 12,
                    borderRadius: 12,
                    backgroundColor: "#FFFFFF",
                    justifyContent: "space-between",
                    ...Platform.select({
                      web: {
                        boxShadow: "rgba(17, 12, 46, 0.05) 0px 6px 12px 0px",
                      },
                      android: { elevation: 10 },
                      ios: {
                        shadowColor: "rgba(0, 0, 0, 0.16)",
                        shadowOffset: {
                          width: 0,
                          height: 1,
                        },
                        shadowOpacity: 1,
                        shadowRadius: 4,
                      },
                    }),
                  }}
                >
                  <CustomText
                    style={{
                      fontWeight: 500,
                      color: "#858490",
                    }}
                  >
                    Apply Promo Code
                  </CustomText>
                  <TouchableOpacity>
                    <AntDesign name="right" size={16} color="#CF4258" />
                  </TouchableOpacity>
                </Pressable>
              )}
            </View>

            {!!data.discount && (
              <View
                style={{
                  width: "100%",
                  marginTop: 8,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <AntDesign
                  name="checkcircle"
                  size={16}
                  color="#34A853"
                  style={{ marginRight: 4 }}
                />
                <CustomText
                  style={{ color: "#34A853", fontSize: 16, fontWeight: 500 }}
                >
                  Yeah!!!
                </CustomText>
                <CustomText style={{ fontSize: 14 }}>
                  {` you got ₹${data.discount} discount`}
                </CustomText>
              </View>
            )}

            <View style={styles.lowerContainer}>
              <Pressable
                style={styles.button}
                onPress={async () => {
                  const orderData = await placeOrder(token, {
                    product_id: productId,
                    template_data_id: templateId,
                    coupon_code: coupon,
                    username,
                  });
                  if (orderData.status === 1) {
                    Linking.openURL(
                      `${config["app.payment.url"]}${orderData.order_id}`
                    );
                  }
                }}
              >
                <CustomText style={styles.buttonText}>
                  Complete Payment
                </CustomText>
              </Pressable>
            </View>
          </>
        )}
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.notification?.loadingSpinner,
  config: state?.cms?.config,
  token: state?.user?.token,
});

const mapDispatchToProps = (dispatch) => ({
  validateOrder: (token, data) => dispatch(validateOrder(token, data)),
  placeOrder: (token, data) => dispatch(placeOrder(token, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 4,
    padding: 16,
    paddingTop: 0,
  },
  divider: {
    width: "100%",
    marginVertical: 2,
    height: StyleSheet.hairlineWidth,
    backgroundColor: "#a2a2a2",
  },
  upperContainer: {
    padding: 16,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#DB5E71",
    fontSize: 32,
    fontWeight: 600,
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#DB5E71",
    width: "90%",
    paddingVertical: 20,
    borderRadius: 20,
    paddingHorizontal: 20,
    position: "fixed",
    bottom: 35,
    maxWidth: 440,
    alignItems: "center",
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  buttonText: {
    color: "white",
    fontSize: 20,
  },

  categoryContainer: {
    flexDirection: "column",
    marginTop: "10%",
    padding: 20,
    backgroundColor: "#FDEBE8",
    borderRadius: 20,
    rowGap: 20,
    justifyContent: "center",
    alignItems: "flex-start",
    marginBottom: 6,
    ...Platform.select({
      web: {
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
});
