import { Image, StyleSheet, Text, View } from "react-native";
import React from "react";
import { useSelector } from "react-redux";
import CustomText from "./CustomText";

const Footer = () => {
  const dashboardData = useSelector((state) => state.cms.dashboard);
  return (
    <>
      <View style={styles.middleContainer}>
        <CustomText style={styles.secondaryHeading}>All Caught Up!</CustomText>
        {/* <Pressable onPress={scrollToTop}>
            <CustomText style={styles.secondaryHeading}>Scroll back to top</CustomText>
          </Pressable> */}
      </View>
      {dashboardData?.cms &&
        dashboardData?.cms.find(
          (item) => item.entity_id === "cms.dashboard.footerBanner"
        ) && (
          <Image
            source={{
              uri: dashboardData?.cms.find(
                (item) => item.entity_id === "cms.dashboard.footerBanner"
              )?.data?.url,
            }}
            style={{ width: "100%", aspectRatio: 1 / 2.5, resizeMode: "cover" }}
          />
        )}
    </>
  );
};

export default Footer;

const styles = StyleSheet.create({
  middleContainer: {
    alignItems: "center",
    padding: 16,
    gap: 16,
  },
  secondaryHeading: {
    color: "#252525",
    fontSize: 16,
    fontWeight: "bold",
  },
});
