import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { connect, useDispatch } from "react-redux";
import { AntDesign, Entypo } from "@expo/vector-icons";
import { PanGestureHandler, State } from "react-native-gesture-handler";
import WebView from "../components/WebView";
import { getProduct } from "../store/actions/cms";
import { profileActions } from "../store/slices/profile";
import CustomText from "../components/CustomText";

const ProductScreen = ({
  getData,
  route,
  loading,
  productData,
  baseUrl,
  navigation,
  categoryId,
  setTemplateId,
}) => {
  const { id, templateId } = route.params;

  const [padding, setPadding] = useState(0);

  useEffect(() => {
    getData(id);
  }, [id]);

  const onSwipeUp = useCallback(
    ({ nativeEvent }) => {
      console.log(nativeEvent, "done");
      setPadding(-1 * nativeEvent.translationY);
      if (nativeEvent.translationY < -40) {
        setPadding(0);
        navigation.navigate("ProfileCards", {
          isPreviewSelection: templateId,
        });
      }
    },
    [navigation]
  );
  const onPanHandlerStateChange = (event) => {
    if (event.nativeEvent.state === State.END) {
      setPadding(0);
    }
  };

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <View style={styles.upperContainer}>
        <WebView
          iStyle={{ flex: 1 }}
          scrollAllowed
          uri={`${baseUrl}${id}?templateDataId=${
            templateId ? templateId : productData?.template_data_id
          }`}
        />
      </View>
      <View style={styles.lowerContainer}>
        {!templateId ? (
          <>
            <CustomText style={styles.phoneHeading}>
              {productData?.name}
            </CustomText>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                columnGap: 16,
              }}
            >
              <View style={{ flex: 1 }}>
                <CustomText style={styles.secondaryHeading}>
                  {productData?.description}
                </CustomText>
                <CustomText style={styles.secondaryHeading}>
                  ₹{productData?.variant[0]?.price}
                </CustomText>
              </View>
              <View style={{ flex: 1 }}>
                <Pressable
                  style={styles.button}
                  onPress={() => {
                    setTemplateId(id);
                    if (categoryId) {
                      navigation.navigate("Create");
                    } else {
                      navigation.navigate("ProfileCategory");
                    }
                  }}
                >
                  <CustomText style={styles.buttonText}>Create</CustomText>
                </Pressable>
              </View>
            </View>
          </>
        ) : (
          <View
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                height: 50,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Pressable
                style={[styles.button, { width: "100%" }]}
                onPress={() => {
                  navigation.navigate("PreCheckout", {
                    productId: id,
                    templateId,
                  });
                }}
              >
                <CustomText style={styles.buttonText}>Buy Now</CustomText>
              </Pressable>
            </View>
            <PanGestureHandler
              activeCursor="grab"
              style={{
                flex: 1,
              }}
              onGestureEvent={onSwipeUp}
              onHandlerStateChange={onPanHandlerStateChange}
            >
              <View
                style={{
                  flex: 1,
                  alignItems: "flex-end",
                  justifyContent: "center",
                  flexDirection: "row",
                  columnGap: 8,
                  paddingBottom: padding,
                }}
              >
                <CustomText
                  style={{ fontWeight: 500, fontSize: 20, color: "#DB5E71" }}
                >
                  Try More? we have got you!
                </CustomText>
                <AntDesign
                  name="up"
                  size={24}
                  color="#DB5E71"
                  style={{ marginHorizontal: 15 }}
                />
              </View>
            </PanGestureHandler>
          </View>
        )}
      </View>
    </View>
  );
};

const mapStateToProps = (state) => ({
  productData: state?.cms?.product,
  categoryId: state?.profile?.categoryId,
  baseUrl: state?.cms?.config["app.template.base_url.v2"],
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (id) => dispatch(getProduct(id)),
  setTemplateId: (id) => dispatch(profileActions.setTemplateId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
    borderTopEndRadius: 40,
    borderTopStartRadius: 40,
    overflow: "hidden",
  },
  upperContainer: {
    flex: 5,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 20,
    fontWeight: "bold",
  },
  secondaryHeading: {
    color: "#00000080",
    fontSize: 20,
  },
  lowerContainer: {
    padding: 16,
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "space-evenly",
  },
  button: {
    backgroundColor: "#DB5E71",
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
  },
});
