import { Pressable, StyleSheet, Text, View, Platform } from "react-native";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import ListView from "../components/ListView";
import StarRating from "../components/StarRating";
import Input from "../components/Input";
import { notificationActions } from "../store/slices/notification";
import { submitReview } from "../store/actions/cms";
import CustomText from "../components/CustomText";
import { loginRequired } from "../utils/loginRequired";

const SubmitReviewScreen = ({ route, navigation, loading, token }) => {
  const { id } = route.params;
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    review: "",
    rating: 0,
    username: id,
  });

  const handleRatingChange = (value) => {
    setUserData((prev) => ({
      ...prev,
      rating: value,
    }));
  };

  useEffect(() => {
    if (!token) loginRequired(navigation);
  }, []);

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Review</CustomText>
      </View>
      <View style={styles.container}>
        <StarRating
          rating={userData.rating}
          onRatingChange={handleRatingChange}
        />
        <View style={styles.middleContainer}>
          {/* <CustomText style={styles.phoneHeading}>Review</CustomText> */}
          <Input
            placeholder="Review"
            multiline={true}
            styleProp={{
              // borderColor: "#f09aab",
              // borderWidth: 1,
              padding: 12,
              borderRadius: 12,
            }}
            boxHeight={104}
            onChangeText={(e) => {
              setUserData((prev) => ({
                ...prev,
                review: e,
              }));
            }}
          />
        </View>
        <View style={styles.lowerContainer}>
          <Pressable
            style={styles.button}
            onPress={async () => {
              if (!loading) {
                dispatch(notificationActions.toggleError());
                if (
                  userData.review &&
                  userData.review.length !== 0 &&
                  userData.rating !== 0
                ) {
                  const data = await dispatch(
                    submitReview(token, { ...userData })
                  );
                  if (data.order_rating_review_id) {
                    console;
                    navigation.replace("Homeroutes", {
                      screen: "Dashboard",
                    });
                  }
                } else if (!userData.review || userData.review.length === 0) {
                  dispatch(
                    notificationActions.toggleError("Please enter review")
                  );
                } else {
                  dispatch(
                    notificationActions.toggleError("Please give rating")
                  );
                }
              }
            }}
          >
            <CustomText style={styles.buttonText}>Submit</CustomText>
          </Pressable>
        </View>
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.notification?.loading,
  token: state?.user?.token,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitReviewScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    padding: 16,
    paddingTop: 40,
  },
  upperContainer: {
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#32505C",
    fontSize: 24,
    fontWeight: 400,
  },
  middleContainer: {
    borderRadius: 20,
    width: "100%",
    marginTop: 20,
    alignItems: "flex-start",
    // backgroundColor: "#DB5E71",
    padding: 16,
    gap: 16,
  },
  phoneHeading: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#DB5E71",
    width: "90%",
    paddingVertical: 20,
    borderRadius: 20,
    paddingHorizontal: 20,
    position: "fixed",
    bottom: 80,
    maxWidth: 440,
    alignItems: "center",
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  buttonText: {
    color: "white",
    fontSize: 20,
  },
});
