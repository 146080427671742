import {
  ActivityIndicator,
  Image,
  Pressable,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React, { useLayoutEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Entypo } from "@expo/vector-icons";
import Input from "../components/Input";
import { generateOtp, verifyOtp } from "../store/actions/auth";
import { useState } from "react";
import { userActions } from "../store/slices/auth";
import { notificationActions } from "../store/slices/notification";
import { useEffect } from "react";
import ProfileList from "../components/ProfileList";
import ImageSlider from "../components/ImageSlider";
import { getDashboard, getProducts } from "../store/actions/cms";
import CategoryList from "../components/CategoryList";
import Footer from "../components/Footer";
import CreateCardsList from "../components/CreateCardsList";
import AsyncStorage from "@react-native-async-storage/async-storage";

const ProfileCardsPage = ({
  getData,
  dashboardData,
  categories,
  loading,
  navigation,
  products,
  route,
  getProductsData,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      await AsyncStorage.setItem("redirectToProfileCards", 0);
      if (!dashboardData) {
        dispatch(notificationActions.toggleSpinnerLoading(true));
        await Promise.all([getData(1), getProductsData(1)]);
        dispatch(notificationActions.toggleSpinnerLoading(false));
      } else {
        if (!products) await getProductsData();
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <ScrollView
        style={[
          styles.container,
          // {
          //   ...Platform.select({
          //     web: {
          //       scrollbarWidth: "none",
          //       /* For Firefox */
          //       scrollbarColor: "transparent transparent",
          //       /* For Chrome, Safari, and Edge */
          //       "-ms-overflow-style": "none" /* For Internet Explorer/Edge */,
          //       "::-webkit-scrollbar": {
          //         display: "none" /* For Chrome, Safari, and Edge */,
          //       },
          //     },
          //   }),
          // },
        ]}
        // ref={scrollViewRef}
        showsVerticalScrollIndicator={false}
      >
        {products && products?.length > 0 && (
          <CreateCardsList
            data={products}
            isSelectionMode={route.params?.isPreviewSelection}
          />
        )}
        <Footer />
      </ScrollView>
    </View>
  );
};

const mapStateToProps = (state) => ({
  categories: state?.cms?.dashboard?.category,
  dashboardData: state?.cms?.dashboard,
  products: state?.cms?.products,
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (stop) => dispatch(getDashboard(stop)),
  getProductsData: (stop) => dispatch(getProducts(stop)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCardsPage);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  categoryContainer: {
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 4,
    marginRight: 8,
    justifyContent: "space-between",
    alignItems: "center",
    rowGap: 20,
    backgroundColor: "#f5e6eb",
  },
  mainHeading: {
    color: "#DB5E71",
    fontSize: 28,
    fontWeight: "bold",
  },
  secondaryHeading: {
    color: "#252525",
    fontSize: 16,
    fontWeight: "bold",
  },
  categoryText: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "white",
    borderWidth: 3,
    borderColor: "#DB5E71",
    borderRadius: 12,
    paddingVertical: 4,
    paddingHorizontal: 20,
    margin: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "#DB5E71",
    fontSize: 16,
    fontWeight: "bold",
  },
});
