export const generateShareContent = (config, templateData, order) => {
  let shareContent = `${config["app.cms.template_share_text"]}`;

  let TD_NAME = templateData["personal_info_business_name"];
  let TD_SUB_CATEGORY = templateData["profile_sub_category"];
  let TD_ADDRESS = templateData["personal_info_business_address"];
  let TD_ADDRESS_LINK = templateData["personal_info_business_location"];

  let TD_REACH_OUT = templateData["social_links_social_links"];

  let TD_RO_STR = "Reach Out :\n\n";

  if (TD_REACH_OUT) {
    for (let [key, value] of Object.entries(TD_REACH_OUT)) {
      let linkType = value["link_type"];
      let linkValue = value["link_value"];
      let linkShowHeader = value["link_show_header"];

      if (linkShowHeader === "enabled") {
        TD_RO_STR += `${linkType}: ${linkValue}\n\n`;
      }
    }
  }

  if (TD_RO_STR === "Reach Out :\n\n") {
    let count = 1;
    if (TD_REACH_OUT) {
      for (let [key, value] of Object.entries(TD_REACH_OUT)) {
        let linkType = value["link_type"];
        let linkValue = value["link_value"];

        if (count < 4) {
          TD_RO_STR += `${linkType}: ${linkValue}\n\n`;
        }
        count++;
      }
    }
  }

  if (TD_ADDRESS_LINK === "") {
    TD_ADDRESS_LINK = "\n\n";
  } else {
    TD_ADDRESS_LINK = `Location: ${TD_ADDRESS_LINK}\n\n`;
  }

  shareContent = `TD_NAME\n(TD_SUB_CATEGORY)\n\nWebsite : TEMPLATE_URL\n\nAddress : TD_ADDRESS\nTD_LOCATIONTD_REACH_OUT\nEVERYTHING ABOUT YOU IN ONE SINGLE LINK with KHOJO Right Now (Startup registered with GOI).\nCreate your KHOJO Profile Now: https://app.khojorightnow.com`;

  let templateCode = order["items"][0]["username"];
  // .toString()
  // .replace("order_", "")
  // .toString()
  // .replace("pay_", "");

  let templateUrl = `${config["app.template.share_url.v2"]}${templateCode}?is_review=1`;

  shareContent = shareContent.replace("TD_NAME", TD_NAME);
  shareContent = shareContent.replace("TD_SUB_CATEGORY", TD_SUB_CATEGORY);
  shareContent = shareContent.replace("TD_ADDRESS", TD_ADDRESS);
  shareContent = shareContent.replace("TD_REACH_OUT", TD_RO_STR);
  shareContent = shareContent.replace("TD_LOCATION", TD_ADDRESS_LINK);
  shareContent = shareContent.replace("TEMPLATE_URL", templateUrl);

  let categoryName =
    order["items"][0]["product"]["category"]["data"]["name"].toString();
  shareContent = shareContent.replace("CATEGORY_NAME", categoryName);

  return shareContent;
};
