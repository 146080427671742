import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  Platform,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getTemplateFormData,
  getTemplatePreData,
  submitTemplateData,
} from "../store/actions/cms";
import Accordion from "../components/Accordian";
import { notificationActions } from "../store/slices/notification";
import { profileActions } from "../store/slices/profile";
import CustomText from "../components/CustomText";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { loginRequired } from "../utils/loginRequired";
import { useRoute } from "@react-navigation/native";

const FormScreen = ({
  loading,
  getData,
  getPreData,
  formData,
  navigation,
  categoryId,
  token,
  route,
  populateTemplateData,
  subCategoryId,
  templateId,
  createTemplate,
}) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const page = await AsyncStorage.getItem("formPage");
      console.log("i ran", page);
      if (page) {
        setExpanded({ [page]: true });
      } else {
        setExpanded({ 0: true });
      }
    };
    fetchData();

    return () => AsyncStorage.removeItem("formPage");
  }, []);

  const toggleExpand = async (index) => {
    await AsyncStorage.setItem("formPage", index);
    setExpanded((prevState) => ({
      [index]: true,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      // if (!formData) {
      dispatch(notificationActions.toggleSpinnerLoading(true));
      await Promise.all(
        // {
        //   true: [],
        //   [!formData && !edit]: [getData(token), getPreData(token)],
        //   [formData && !edit]: [getPreData(token)],
        //   [!formData && edit]: [getData(token)],
        // }[true]
        !formData && !route.params?.edit
          ? [getData(token), getPreData(token)]
          : !formData
          ? [getData(token)]
          : !route.params?.edit
          ? [getPreData(token)]
          : [dispatch(notificationActions.toggleSpinnerLoading(false))]
      );
      dispatch(notificationActions.toggleSpinnerLoading(false));
      // }
    };
    if (token) {
      if (!categoryId || !subCategoryId || !templateId) {
        setTimeout(
          () =>
            navigation.replace("Homeroutes", {
              screen: "Dashboard",
            }),
          100
        );
      } else {
        fetchData();
      }
    } else {
      loginRequired(navigation, route.name);
    }
  }, [token]);

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <View style={{ flex: 1, alignItems: "center" }}>
      <View style={{ flex: 1, marginBottom: 80, width: "100%" }}>
        <Accordion
          data={
            formData?.find(
              (item) =>
                item.entity_id ===
                `master.page.template_data.profile.v2.${categoryId}`
            )?.data?.pages
          }
          populateTemplateData={populateTemplateData}
          expanded={expanded}
          toggleExpand={toggleExpand}
        />
      </View>
      <View style={{ alignItems: "center" }}>
        <Pressable
          style={styles.button}
          onPress={async () => {
            let res;
            AsyncStorage.removeItem("formPage");
            if (route.params?.edit) {
              console.log(route.params?.edit);
              res = await createTemplate(token, route.params?.edit);
            } else {
              res = await createTemplate(token);
            }
            if (res.status) {
              if (route.params?.edit) {
                navigation.navigate("Homeroutes", {
                  screen: "Profiles",
                  params: {
                    screen: "OrderHistory",
                  },
                });
              } else {
                navigation.navigate("Product", {
                  id: res.data.product_id,
                  templateId: res.template_data_id,
                });
              }
            }
          }}
        >
          <CustomText style={styles.buttonText}>
            {route.params?.edit ? "SAVE" : "Create"}
          </CustomText>
        </Pressable>
      </View>
    </View>
  );
};

const mapStateToProps = (state) => ({
  formData: state?.cms?.formData,
  categoryId: state?.profile?.categoryId,
  templateId: state?.profile?.templateId,
  populateTemplateData: state?.profile?.templateData,
  subCategoryId: state?.profile?.subCategoryId,
  loading: state?.notification?.loadingSpinner,
  token: state?.user?.token,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (token) => dispatch(getTemplateFormData(token)),
  getPreData: (token) => dispatch(getTemplatePreData(token)),
  createTemplate: (token, edit) => dispatch(submitTemplateData(token, edit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormScreen);

const styles = StyleSheet.create({
  button: {
    backgroundColor: "#DB5E71",
    width: "90%",
    paddingVertical: 20,
    borderRadius: 20,
    paddingHorizontal: 20,
    position: "fixed",
    bottom: 15,
    maxWidth: 440,
    alignItems: "center",
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  buttonText: {
    color: "white",
    fontSize: 20,
  },
});
