import {
  FlatList,
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
  Platform,
  TextInput,
} from "react-native";
import React, { useState } from "react";
import { FontAwesome, Entypo } from "@expo/vector-icons";
import CustomText from "./CustomText";

const ListView = ({
  data,
  title,
  onPress = () => {},
  uniqueKey,
  MainIcon,
  hideImage,
}) => {
  const [text, setText] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleClearText = () => {
    setText("");
  };

  return (
    <View style={styles.container}>
      {title && <CustomText style={styles.mainHeading}>{title}</CustomText>}

      {/* {!hideSearchBar && (
        <View
          style={{
            borderBottomWidth: 2,
            borderBottomColor: isFocused ? "#389cf8" : "grey",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              height: 50,
              backgroundColor: "white",
            }}
          >
            <FontAwesome
              name="search"
              size={20}
              color={isFocused ? "#389cf8" : "grey"}
              style={{ padding: 10 }}
            />
            <CustomTextInput
              style={{
                flex: 1,
                height: "100%",
                color: isFocused ? "#389cf8" : "black",
                caretColor: "#389cf8",
                caretWidth: 2,
                paddingHorizontal: 20,
                outlineWidth: 0,
                outlineColor: "transparent",
              }}
              value={text}
              onChangeText={setText}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              placeholder="Search"
            />

            <Pressable onPress={handleClearText} style={{ padding: 10 }}>
              <Entypo
                name="cross"
                size={20}
                color={isFocused ? "#389cf8" : "grey"}
              />
            </Pressable>
          </View>
        </View>
      )} */}

      <FlatList
        style={{ width: "100%" }}
        data={data?.filter((item) => {
          if (text) {
            return item.title.toLowerCase().includes(text.toLowerCase());
          } else {
            return true;
          }
        })}
        showsVerticalScrollIndicator={false}
        renderItem={(itemData) => {
          const { Icon } = itemData.item;
          return (
            <Pressable
              style={[
                styles.categoryContainer,
                {
                  backgroundColor:
                    itemData.index % 2 === 0 ? "#FDEBE8" : "#DB5E71",
                  minHeight: 80,
                },
              ]}
              onPress={() => {
                onPress(itemData.item[uniqueKey]);
              }}
            >
              <View style={{ columnGap: 4 }}>
                <CustomText
                  style={{
                    fontWeight: 600,
                    fontSize: 24,
                    color: itemData.index % 2 === 0 ? "#DB5E71" : "#ffffff",
                  }}
                >
                  {itemData.item.title}
                </CustomText>
                {!!itemData.item.subTitle && (
                  <CustomText>{itemData.item.subTitle}</CustomText>
                )}
              </View>
              {MainIcon ? (
                MainIcon
              ) : !!Icon ? (
                Icon
              ) : !hideImage ? (
                <Image
                  source={{ uri: itemData.item.image_url }}
                  style={[
                    {
                      resizeMode: "contain",
                      width: 60,
                      height: 60,
                    },
                    //   tailwind`rounded-xl w-10/12`,
                  ]}
                />
              ) : (
                <></>
              )}
            </Pressable>
          );
        }}
        keyExtractor={(item) => {
          return item[uniqueKey];
        }}
        alwaysBounceVertical={false}
      />
    </View>
  );
};

export default ListView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    rowGap: 20,
    alignItems: "center",
  },
  mainHeading: {
    color: "#32505C",
    fontSize: 24,
    fontWeight: "700",
  },
  secondaryHeading: {
    color: "#7b7b7b",
    fontSize: 16,
  },
  categoryContainer: {
    borderRadius: 20,
    flex: 1,
    flexDirection: "row",
    paddingHorizontal: 20,
    paddingLeft: 40,
    paddingVertical: 10,
    columnGap: 20,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
});
