import { Pressable, StyleSheet, Text, View } from "react-native";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import WebView from "../components/WebView";
import CustomText from "../components/CustomText";

const ProfileView = ({ route, config, navigation }) => {
  const { id } = route.params;

  const url = `${config["app.template.share_url.v2"]}${id}?is_review=0`;

  useEffect(() => {
    if (!url) {
      setTimeout(() => {
        navigation.replace("Homeroutes", {
          screen: "Dashboard",
        });
      }, 100);
    }
  }, [url]);

  return (
    <>
      <View style={styles.container}>
        <WebView uri={url} iStyle={{ flex: 1 }} scrollAllowed />
      </View>
      <View style={styles.lowerContainer}>
        <View
          style={{ flex: 1, padding: 6, columnGap: 5, flexDirection: "row" }}
        >
          <Pressable
            style={styles.button}
            onPress={() => {
              navigation.navigate("SubmitReview", {
                id,
              });
            }}
          >
            <CustomText style={styles.buttonText}>Review</CustomText>
          </Pressable>
          <Pressable
            style={styles.button}
            onPress={() => {
              navigation.navigate("SubmitReview", {
                id,
              });
            }}
          >
            <CustomText style={styles.buttonText}>Rating</CustomText>
          </Pressable>
        </View>
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  config: state?.cms?.config,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    overflow: "hidden",
    backgroundColor: "white",
  },
  lowerContainer: {
    height: 48,
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#DB5E71",
    flexDirection: "row",
    flex: 1,
    paddingVertical: 3,
    paddingHorizontal: 10,
    justifyContent: "center",
    columnGap: 2,
    alignItems: "center",
    borderRadius: 4,
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
});
