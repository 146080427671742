import { createSlice } from "@reduxjs/toolkit";

const initial_services = {
  service: "",
  event_clip: {},
  select: true,
};

const initial_quick_information = {
  qi_type: "Category",
  event_clip: {},
  select: true,
  qi_value: "",
};

const initial_social_links = {
  link_type: "",
  select: true,
  link_show_header: "disabled",
  event_clip: {
    status: true,
    img_id: "0dc151da-da25-43f0-b9a3-f8639b229423",
    name: "instagram.png",
    path: "original/0dc151da-da25-43f0-b9a3-f8639b229423.png",
    filetype: "image/png",
    url: "https://bin-cdn-krn.s3.us-east-2.amazonaws.com/original/6aed7938-579b-4a79-bb89-84296862fd12.png",
    multimedia_id: "2671d160-3957-4104-a165-493b822d675d",
  },
  link_value: "",
};

const returnObject = (key) => {
  switch (key) {
    case "services":
      return initial_services;

    case "social_links":
      return initial_social_links;

    case "quick_information":
      return initial_quick_information;
    default:
      break;
  }
};

const initialState = {
  categoryId: null,
  subCategoryId: null,
  subCategoryTitle: null,
  categoryTitle: null,
  templateId: null,
  // categoryId: "5f594b35-5d3a-4670-9c36-d94f0989fd63",
  // subCategoryId: "3df9370a-a499-4f2c-acbd-0c74ad16cdb1",
  // subCategoryTitle: "Students",
  // categoryTitle: "Individual",
  // templateId: "2eb4554c-bfe0-4c5a-8f89-30fb9a9a18f2",
  templateData: {
    personal_info_profile_photo: [],
    gallery_gallery: [],
    quick_information_quick_information: [initial_quick_information],
    services_services: [initial_services],
    social_links_social_links: [initial_social_links],
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setCategoryId: (state, action) => {
      state.categoryId = action.payload.id;
      state.categoryTitle = action.payload.title;
    },
    setAllData: (state, action) => {
      state.categoryId = action.payload.categoryId;
      state.categoryTitle = action.payload.categoryTitle;
      state.templateId = action.payload.templateId;
      state.subCategoryId = action.payload.subCategoryId;
      state.subCategoryTitle = action.payload.subCategoryTitle;
    },
    setTemplateData: (state, action) => {
      state.templateData = action.payload;
    },
    setSubCategoryId: (state, action) => {
      state.subCategoryId = action.payload.id;
      state.subCategoryTitle = action.payload.title;
    },
    setTemplateId: (state, action) => {
      state.templateId = action.payload;
    },
    resetProfileSelection: (state, action) => {
      return initialState;
    },
    updateTemplateData: (state, action) => {
      state.templateData[action.payload.key] = action.payload.value;
    },
    addTemplateData: (state, action) => {
      state.templateData[action.payload.key] = [
        ...state.templateData[action.payload.key],
        returnObject(action.payload.innerKey),
      ];
    },
    deleteTemplateData: (state, action) => {
      const updatedData = [...state.templateData[action.payload.key]];

      // Remove the item at the specified index
      updatedData.splice(action.payload.index, 1);

      // Update the state with the modified data
      state.templateData[action.payload.key] = updatedData;
    },
    updateTemplateObjectData: (state, action) => {
      if (state.templateData[action.payload.key]) {
        state.templateData[action.payload.key][action.payload.index] =
          action.payload.value;
      } else {
        state.templateData[action.payload.key] = [action.payload.value];
      }
    },
    updateTemplateObjectKeyData: (state, action) => {
      state.templateData[action.payload.key][action.payload.index][
        action.payload.innerKey
      ] = action.payload.value;
    },
  },
});

// Action creators are generated for each case reducer function
export const profileActions = profileSlice.actions;

export default profileSlice.reducer;
