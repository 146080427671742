import {
  View,
  Text,
  FlatList,
  StyleSheet,
  Pressable,
  Image,
  Platform,
} from "react-native";
import React from "react";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { connect, useDispatch, useSelector } from "react-redux";
import { profileActions } from "../store/slices/profile";
import CustomText from "./CustomText";
import { submitTemplateData } from "../store/actions/cms";

const CreateCardsList = ({ data, isSelectionMode, token, createTemplate }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const categoryId = useSelector((state) => state?.profile?.categoryId);
  return (
    <View style={styles.container}>
      <FlatList
        data={data}
        ItemSeparatorComponent={<View style={{ padding: 8 }}></View>}
        showsVerticalScrollIndicator={false}
        numColumns={2}
        renderItem={(itemData) => {
          if (!isSelectionMode) {
            return (
              <View
                style={[
                  styles.categoryContainer,
                  {
                    ...Platform.select({
                      ios: {
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                        shadowOffset: { width: 0, height: 5 },
                        shadowOpacity: 1,
                        shadowRadius: 5,
                      },
                      // For Android
                      android: {
                        elevation: 15,
                      },
                    }),
                  },
                ]}
              >
                <Image
                  source={{ uri: itemData.item.images[0].url }}
                  style={[
                    {
                      resizeMode: "cover",
                      width: "50%",
                      aspectRatio: 1 / 1.5,
                      borderRadius: 20,
                      ...Platform.select({
                        web: {
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                          // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        },
                        android: {
                          elevation: 10,
                        },
                        ios: {
                          shadowColor: "rgba(0, 0, 0, 0.16)",
                          shadowOffset: {
                            width: 0,
                            height: 1,
                          },
                          shadowOpacity: 1,
                          shadowRadius: 4,
                        },
                      }),
                      // height: 147,
                    },
                  ]}
                />

                <Pressable
                  style={styles.button}
                  onPress={() => {
                    navigation.replace("Product", {
                      id: itemData.item.product_id,
                    });
                  }}
                >
                  <Ionicons name="expand" size={16} color="#ffffff" />
                </Pressable>

                <Pressable
                  style={{
                    backgroundColor: "#DB5E71",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 8,
                  }}
                  onPress={() => {
                    dispatch(
                      profileActions.setTemplateId(itemData.item.product_id)
                    );

                    if (categoryId) {
                      navigation.replace("Create");
                    } else {
                      navigation.replace("ProfileCategory");
                    }
                  }}
                >
                  <CustomText style={styles.buttonText}>Create</CustomText>
                </Pressable>
              </View>
            );
          } else {
            return (
              <Pressable
                onPress={async () => {
                  await dispatch(
                    profileActions.setTemplateId(itemData.item.product_id)
                  );
                  const res = await createTemplate(token);

                  if (res.status) {
                    navigation.replace("Product", {
                      id: res.data.product_id,
                      templateId: res.template_data_id,
                    });
                  }
                }}
                style={[
                  styles.categoryContainer,
                  {
                    ...Platform.select({
                      ios: {
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                        shadowOffset: { width: 0, height: 5 },
                        shadowOpacity: 1,
                        shadowRadius: 5,
                      },
                      // For Android
                      android: {
                        elevation: 15,
                      },
                    }),
                  },
                ]}
              >
                <Image
                  source={{ uri: itemData.item.images[0].url }}
                  style={[
                    {
                      resizeMode: "cover",
                      width: "50%",
                      aspectRatio: 1 / 1.5,
                      borderRadius: 20,
                      ...Platform.select({
                        web: {
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                          // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        },
                        android: {
                          elevation: 10,
                        },
                        ios: {
                          shadowColor: "rgba(0, 0, 0, 0.16)",
                          shadowOffset: {
                            width: 0,
                            height: 1,
                          },
                          shadowOpacity: 1,
                          shadowRadius: 4,
                        },
                      }),
                      // height: 147,
                    },
                  ]}
                />
              </Pressable>
            );
          }
        }}
        keyExtractor={(item) => {
          return item.profile_category_id;
        }}
        alwaysBounceVertical={false}
      />
    </View>
  );
};

const mapStateToProps = (state) => ({
  token: state?.user?.token,
});

const mapDispatchToProps = (dispatch) => ({
  createTemplate: (token, edit) => dispatch(submitTemplateData(token, edit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCardsList);

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    // paddingBottom: 16,
    rowGap: 12,
  },
  categoryContainer: {
    paddingTop: 12,
    flex: 1,
    rowGap: 12,
    overflow: "hidden",
    marginHorizontal: 8,
    borderRadius: 20,
    alignItems: "center",
    backgroundColor: "#ffffff",
    ...Platform.select({
      web: {
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  headingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
  },
  secondaryHeading: {
    color: "#a2a2a2",
    fontSize: 10,
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "#DB5E71",
    padding: 8,
    borderRadius: 8,
    position: "absolute",
    top: 16,
    right: 16,
    alignItems: "center",
    ...Platform.select({
      web: {
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  buttonText: {
    color: "white",
    fontSize: 20,
  },
});
